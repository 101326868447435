import React, { useContext } from 'react';
import { HubHeader, HubFooter, Terms, EventSponsor, SEO, EmailHandler } from 'components';
import { LocalContext } from 'context';
import { defaultColors } from 'styles';
import { useEventData } from 'hooks';

const Home = () => {
  const { scrollTo, registerRef } = useContext(LocalContext);
  const { edges: events } = useEventData();

  return (
    <>
      <SEO pageSpecificTitle="The Forum" />
      <EmailHandler />
      <HubHeader
        scrollToRegister={() => scrollTo(registerRef)}
        colors={defaultColors}
        events={events}
      />
      <EventSponsor eventSponsor={events[13].node.frontmatter.sponsor} />
      <Terms />
      <HubFooter />
    </>
  );
};

export default Home;
